<template>
    <app-page no-container>
        <template #page-header>
            <page-header name="Domains" :loading="loading" v-model="search"></page-header>
        </template>
        <b-overlay id="domains" :show="loading" rounded="sm">
            <b-table v-if="!isMobile()" sticky-header="600px" responsive="md" borderless hover :fields="fields" :items="filteredDomains">
                <template #cell(domain)="data">
                    <span>{{ data.value }} </span>
                </template>

                <template #cell(godaddy)="data">
                    <a :href="`https://dcc.godaddy.com/control/dnsmanagement?domainName=${data.item.domain}`" target="_blank"><i class="fas fa-arrow-up-right-from-square"></i></a>
                </template>

                <template #cell(dmarc)="data">
                    <b-checkbox v-model="data.item.dmarc"></b-checkbox>
                </template>

                <template #cell(eyein_send)="data">
                    <b-checkbox v-model="data.item.eyein_send" :disabled="data.item.eyein_receive"></b-checkbox>
                </template>

                <template #cell(eyein_receive)="data">
                    <b-checkbox v-model="data.item.eyein_receive" @input="eyeinReceivedChanged(data.item)" :disabled="data.item.gmail_receive"></b-checkbox>
                </template>

                <template #cell(sendgrid_send)="data">
                    <b-checkbox v-model="data.item.sendgrid_send" disabled></b-checkbox>
                </template>

                <template #cell(gmail_send)="data">
                    <b-checkbox v-model="data.item.gmail_send" disabled></b-checkbox>
                </template>

                <template #cell(gmail_receive)="data">
                    <b-checkbox :key="data.value" v-model="data.item.gmail_receive" disabled></b-checkbox>
                </template>

                <template #cell(actions)="data">
                    <div class="text-center">
                        <b-btn @click="checkDomain(data.item)" size="sm" :disabled="saving" class="mr-1">Check</b-btn>
                        <b-btn @click="saveDomain(data.item)" size="sm" :disabled="saving">Save</b-btn>
                    </div>
                </template>
            </b-table>
            <div class="accordion" role="tablist" v-else>
                <b-card no-body class="mb-1" v-for="domain in filteredDomains" :key="domain.domain">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle="domain.domain" variant="secondary">{{ domain.domain }}</b-button>
                    </b-card-header>
                    <b-collapse :id="domain.domain" accordion="domains-accordion" role="tabpanel">
                        <b-card-body>
                            <b-btn @click="checkDomain(domain)" size="sm" :disabled="saving"><i class="fas fa-refresh"></i> Check domain</b-btn>
                            <b-row class="mt-2 text-left">
                                <b-col>
                                    <b-form-group>
                                        <b-checkbox v-model="domain.dmarc">DMARC</b-checkbox>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group>
                                        <b-checkbox v-model="domain.eyein_send" :disabled="domain.eyein_receive">Eyein Send</b-checkbox>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group>
                                        <b-checkbox v-model="domain.eyein_receive" @input="eyeinReceivedChanged(domain)" :disabled="domain.gmail_receive">Eyein Receive</b-checkbox>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group>
                                        <b-checkbox v-model="domain.sendgrid_send" disabled>Sendgrid Send</b-checkbox>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group>
                                        <b-checkbox v-model="domain.gmail_send" disabled>Gmail Send</b-checkbox>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group>
                                        <b-checkbox v-model="domain.gmail_receive" disabled>Gmail Receive</b-checkbox>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-btn @click="saveDomain(domain)" size="sm" :disabled="saving" variant="success"><i class="fas fa-save"></i> Save changes</b-btn>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </b-overlay>
    </app-page>
</template>

<script>
import Network from "../../vue-components/helpers/Network.js";

export default {
    name: `domains`,
    data() {
        return {
            loading: true,
            saving: false,
            domains: [],
            fields: [],
            selectedRow: {},
            dnsChecks: {},
            creationMode: false,
            search: ``
        }
    },
    computed: {
        filteredDomains() {
            if (!this.search) {
                return this.domains;
            }

            return this.domains.filter(p => p.domain.toLowerCase().includes(this.search.toLowerCase()))
        }
    },
    activated() {
        this.unwatch = this.$store.watch(
            (state, getters) => getters.cluster,
            () => {
                this.init();
            }
        );
        this.init();
    },
    deactivated() {
        this.unwatch();
    },
    methods: {
        isMobile() {
            return window.screen.width <= 760;
        },
        init() {
            if (this.$store.state.cluster !== `live`) {
                this.fields = [
                    {key: `domain`, sortable: true},
                    {key: `godaddy`, label: `GoDaddy`, sortable: false},
                    {key: `dmarc`, label: `DMARC`, sortable: true},
                    {key: `eyein_send`, sortable: true},
                    {key: `eyein_receive`, sortable: true},
                    {key: `actions`, sortable: false}
                ]
            } else {
                this.fields = [
                    {key: `domain`, sortable: true},
                    {key: `godaddy`, label: `GoDaddy`, sortable: false},
                    {key: `dmarc`, label: `DMARC`, sortable: true},
                    {key: `eyein_send`, sortable: true},
                    {key: `eyein_receive`, sortable: true},
                    {key: `sendgrid_send`, sortable: true},
                    {key: `gmail_send`, sortable: true},
                    {key: `gmail_receive`, sortable: true},
                    {key: `actions`, sortable: false}
                ]
            }

            this.getDomains();
        },
        getDomains() {
            this.loading = true;
            Network.get(`/api/domains/${this.$store.state.cluster}`)
                .then(resp => {
                    this.domains = resp.data;
                    this.loading = false;
                });
        },
        checkDomain(domain) {
            this.loading = true;
            Network.post(`/api/domain/check/${this.$store.state.cluster}/${domain.domain}`)
                .then(resp => {
                    let d = this.domains.find(d => d.domain === domain.domain);

                    d.dmarc = resp.data.dmarc;
                    d.sendgrid_send = resp.data.sendgrid_send;
                    d.eyein_send = resp.data.eyein_send;
                    d.eyein_receive = resp.data.eyein_receive;
                    d.gmail_send = resp.data.gmail_send;
                    d.gmail_receive = resp.data.gmail_receive;

                    this.loading = false;
                })
                .catch(e => {
                    console.error(e);
                    this.loading = false;
                });
        },
        saveDomain(domain) {
            this.creationMode = false;
            this.saving = true;

            Network.post(`/api/domain/save/${this.$store.state.cluster}`, domain)
                .finally(() => {
                    this.checkDomain(domain);
                    this.saving = false;
                });
        },
        eyeinReceivedChanged(domain) {
            if (domain.eyein_receive) {
                domain.eyein_send = true
            }
        }
    }
};
</script>


<style lang="scss" scoped>
#domains::v-deep .b-table-sticky-header > .table.b-table > thead > tr > th {
    position: sticky !important;
}
</style>
